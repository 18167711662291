/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.printing-modal {
  width: 700px;
}
.printing-modal div {
  font-size: 0.7rem;
}
.page-header {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  justify-content: space-between;
}
.info {
  color: #1890ff;
}
.service-no {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 30px;
}
.service-no-title {
  color: #14837B;
}
.form-detail {
  font-style: italic;
}
.modal-name {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.hg-logo {
  max-width: 25%;
  height: 40%;
}
.service-detail-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 4px;
}
.service-detail-container .service-col {
  flex-basis: 50%;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  border: 1px solid #e8e8e8;
}
.service-detail-container .service-col > label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  min-width: 100px;
  flex-basis: 100px;
  background-color: #f5f5f5;
  padding: 4px 8px;
}
.service-detail-container .service-col > .service-val {
  padding: 4px 8px;
  min-width: 0;
}
.service-detail-container .service-col > .service-val .service-val-row {
  margin-bottom: 2px;
}
.service-detail-container .service-col > .service-val .service-val-row:last-child {
  margin-bottom: 0;
}
.signature {
  display: flex;
  position: relative;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.signature .signature-item {
  display: flex;
  flex-basis: 25%;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-right: 8px;
}
.page-remark {
  display: flex;
  margin-top: 8px;
  justify-content: left;
  align-items: left;
}
@media print {
  div {
    font-size: 0.7rem;
  }
  .service-col > label {
    -webkit-print-color-adjust: exact;
  }
}
