/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pre_service-subsection {
  margin-left: 64px;
  margin-bottom: 32px;
}
.mobile .pre_service-subsection {
  margin-left: 16px;
}
.pre_service-subsection-full {
  margin-bottom: 32px;
}
.pre_service-row {
  margin-bottom: 12px;
}
.pre_service-row label {
  color: #1890ff;
}
.mobile .pre_service-row label {
  margin-bottom: 4px;
}
.pre_service-input-view {
  margin-left: 8px;
  display: inline-block;
}
.pre_service-input-block {
  display: inline-block;
  margin-right: 8px;
}
.mobile .pre_service-input-block {
  display: block;
  margin-bottom: 4px;
  margin-right: 0;
}
.drone-received-container {
  display: flex;
  margin-bottom: 12px;
  flex-direction: row;
  justify-content: space-between;
}
.pre_service-subsection-full {
  margin-bottom: 32px;
}
.right-button {
  margin: 0 0.2rem 0 auto;
}
.files-component {
  margin-right: 2rem;
}
.files-upload {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}
